import React from "react";
import PropTypes from "prop-types";
// import QueueAnim from 'rc-queue-anim';
// import { Button } from 'antd';
import { Element } from "rc-scroll-anim";
// import downloadImg from '../assets/imgs/website-download.png';
import andriod from "../assets/imgs/andriod.png";

class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    contryKey: PropTypes.string,
    navToShadow: PropTypes.func,
  };
  static defaultProps = {
    className: "banner",
  };
  constructor(props) {
    super(props);
    this.state = {
      phDown: "",
      clDown: "",
    };
  }
  componentDidMount() {
    console.log("window.location.origin: ", window.location.origin);
    // let uri = 'http://ok.codeblock.tech'
    fetch(window.location.origin + "/api/download/page/config?hash=1&k=1", {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.ret === 0) {
          const { data = {} } = res;
          this.setState({
            phDown: data.url,
          });
        }
      });
    fetch(window.location.origin + "/api/download/page/config?hash=1&k=2", {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.ret === 0) {
          const { data = {} } = res;
          this.setState({
            clDown: data.url,
          });
        }
      });
  }

  render() {
    const { className, isMobile, navToShadow, contryKey } = this.props;
    const { phDown, clDown } = this.state;
    const tips = {
      cl: {
        title: ["¡Le Ayudamos A ", "Obtener Un Préstamo", "Más Fácilmente!"],
        subtitle: [
          "Registrarse - enviar información - solicitar - retirarse",
          "En 4 pasos sencillos,",
          "obtenga su dinero en un plazo de 2 a 24 horas.",
        ],
        download: "Descargar WePeso",
        url: clDown,
      },
      ph: {
        title: ["A Fast And Reliable", " Loan In Philippine."],
        subtitle: [
          "Max amount up to ₱20000",
          "Loan Period：91Days+",
          "Loan released in 2 hours to 24 hours",
        ],
        download: "WePeso for Android",
        url: phDown,
      },
    };
    const tipStr = tips[contryKey];
    // const appUrl = 'https://WePeso.onelink.me/FkQA/app';
    if (isMobile) {
      return (
        <Element
          id="home"
          component="section"
          className={`banner-mobile-wrapper ${contryKey}-mobile-banner`}
          onChange={navToShadow}
        >
          <div className="banner-mobile">
            <p className="bm-title">{tipStr.title.join(" ")}</p>
            <p className="bm-subtitle"> {tipStr.subtitle[0]}</p>
            <p className="bm-subtitle">{tipStr.subtitle[1]}</p>
            <p className="bm-subtitle">{tipStr.subtitle[2]}</p>
            <div className={`${className}-word-boxs`}>
              <a
                className="bm-download-btn"
                target="_blank"
                href={tipStr.url}
                key="a"
                rel="noopener noreferrer"
              >
                <img
                  className={`${className}-andriod`}
                  src={andriod}
                  alt="img"
                />
                {tipStr.download}
              </a>
            </div>
            {/* <a target="_blank" rel="noopener noreferrer" href={ios} key="a" className={`${className}-word-downloads`}>
              <img className={`${className}-ims`} src={downloadImg} alt="img" />
              <div>
              <img className={`${className}-andriod`}  src={apple} alt="img" />
                {'WePeso for iOS '}
              </div>
              <img className={`${className}-newIcon`} src={newIcon} alt="img"/>
            </a> */}
          </div>
        </Element>
      );
    }

    return (
      <Element
        id="home"
        component="section"
        className={`${className}-wrapper ${contryKey}-${className} page`}
        onChange={navToShadow}
      >
        <div
          className={`${className}  ${
            contryKey === "cl" && className + "-img"
          }`}
        >
          <div className={`${className}-home`}>
            <p className={`${className}-home-title`}>{tipStr.title[0]}</p>
            <p className={`${className}-home-title`}>{tipStr.title[1]}</p>
            {!!tipStr.title[2] && (
              <p className={`${className}-home-title`}>{tipStr.title[2]}</p>
            )}
            <p className={`${className}-home-subTitle`}>{tipStr.subtitle[0]}</p>
            <p className={`${className}-home-subTitle`}>{tipStr.subtitle[1]}</p>
            <p className={`${className}-home-subTitle`}>{tipStr.subtitle[2]}</p>
            <div className={`${className}-word-box`}>
              <a
                className={`${className}-download-btn`}
                style={{ marginRight: 20 }}
                target="_blank"
                href={tipStr.url}
                key="a"
                rel="noopener noreferrer"
              >
                <img
                  className={`${className}-andriod`}
                  src={andriod}
                  alt="img"
                />
                {tipStr.download}
              </a>
            </div>
            {/* <a target="_blank" rel="noopener noreferrer" href={ios} key="a" className={`${className}-word-downloads`}>
              <img className={`${className}-ims`} src={downloadImg} alt="img" />
              <div>
              <img className={`${className}-andriod`}  src={apple} alt="img" />
                {'WePeso for iOS '}
              </div>
              <img className={`${className}-newIcon`} src={newIcon} alt="img"/>
            </a> */}
          </div>
        </div>
      </Element>
    );
  }
}

export default Banner;
