import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";
import chLogo from "../assets/imgs/chili/chLogo.png";
import phLogo from "../assets/imgs/chili/phLogo.png";
import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class PrivacyAgreement extends React.PureComponent {
  state = {
    isMobile,
    localCountryKey: null,
    // isScroll: false,
    localCountrys: [
      { logo: phLogo, name: "Philippine", key: "ph" },
      { logo: chLogo, name: "Chile", key: "cl" },
    ],
  };

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    if (query && query.get("cty")) {
      let cty = query.get("cty");
      this.setState({ localCountryKey: cty });
    }

    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
  }

  render() {
    const { localCountryKey, localCountrys } = this.state;
    return localCountryKey === null ? (
      <div className={"contentWrapper"}>
        <div className={"select"}>
          <div className="countrySelect">
            {localCountrys.map((item) => (
              <div
                className={[
                  "localCountry",
                  item.key === localCountryKey && "seletOn",
                ].join(" ")}
                onClick={() => {
                  this.setState({ localCountryKey: item.key });
                }}
              >
                <img
                  src={item.logo}
                  className="countrylogo"
                  alt="localCountry"
                />
                <span className="countryName">{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    ) : localCountryKey === "ph" ? (
      <div className={"contentWrapper"}>
        <DocumentTitle title="Customer Data Privacy Policy" />
        <h2>Customer Data Privacy Policy</h2>
        <p></p>
        <p>INTRODUCTION</p>
        <p></p>
        <p>
          CASH AID LOAN ONLINE LENDING INC. (“WePeso”, “we”, “us”, “our”) cares
          deeply about how important your Personal Data for you. This Privacy
          Statement explains what we gather, keep, store, use, process and how
          we do it.
        </p>
        <p></p>
        <p>
          This Privacy Statement applies to all past, present and prospective
          WePeso customers and non-customers (such as, but not limited to,
          anyone who transacts with WePeso users from a WePeso Account, visitor
          to our site, anyone who engages our Customer Care channel); each of
          the foregoing, “you” or “your”.
        </p>
        <p></p>
        <p>
          You confirm that you have read, understood and agreed to be bound by
          the WePeso Privacy Statement, which is available at the WePeso website
          and WePeso Mobile Banking Application (“Application”).
        </p>
        <p></p>
        <p>
          You have agreed that WePeso may collect, hold, use, and share your
          Personal Data pursuant to the Data Privacy Act of 2012 (Republic Act
          No. 10173) to WePeso, our affiliates, our merchants, co-branding, and
          other strategic partners (collectively, “merchant-partners”) for as
          long as your records and Personal Data are required and/or allowed by
          law to be retained and processed, whether for your protection or for
          the protection and pursuit of the legitimate interests and/or business
          purposes of WePeso. As used herein, “affiliate” means any person or
          entity that, now or hereafter, directly or indirectly, controls
          (whether singly or together with others), is controlled by, or under
          common control with WePeso, where “control” means the possession,
          directly or indirectly, of the power to direct or cause the direction
          of the management and policies whether through ownership of securities
          or equity interest, management authority, or contract.
        </p>
        <p></p>
        <p>HOW WE OBTAIN YOUR DATA</p>
        <p></p>
        <p>The following are some ways we collect your data:</p>
        <p></p>
        <p>We obtain your data, with your consent, when you:</p>
        <p></p>
        <p>share it with us when you register on our website or Application,</p>
        <p></p>
        <p>
          share it with us when you apply for or avail of any product or service
          offered from time to time by WePeso,
        </p>
        <p></p>
        <p>
          upload documents, files, or images as part of applying for or availing
          of any product or services offered from time to time by WePeso,
        </p>
        <p></p>
        <p>
          sign a contract with WePeso, or accepting offer of product or service
          from WePeso,
        </p>
        <p></p>
        <p>
          interact with any of our employees, representatives, agents and/or
          service providers,
        </p>
        <p></p>
        <p>contact us through one of our channels or visit our websites.</p>
        <p></p>
        <p>
          We collect your Personal Data from your organisation when it becomes a
          partner or if it is an existing partner, and your Personal Data is
          provided to help us contact your organisation, or when your
          organization refers you to WePeso.
        </p>
        <p></p>
        <p>
          We collect your Personal Data from other sources, including, without
          limitation, government agencies and instrumentalities, supervisory
          bodies, professional associations/organizations/clubs, tax
          authorities, courts of competent jurisdiction, other companies, and
          other third party sources.
        </p>
        <p></p>
        <p>* Finance-related SMS Log information</p>
        <p></p>
        <p>
          We only collect your finance-related SMS log from your inbox to our
          server(https://app-ph.we-peso.com) to evaluate the customer's credit,
          and we cannot correctly evaluate whether the customer meets the credit
          conditions required to reach the loan. This enables you to take
          financial facilities from the regulated financial entities and other
          service providers available on the platform.
        </p>
        <p></p>
        <p>
          While using the app, it periodically sends the finance-related SMS
          information to our affiliate server and to us.
        </p>
        <p></p>
        <p>
          We collect finance-related SMS information only after obtaining your
          additional explicit consent.
        </p>
        <p></p>
        <p>
          We will delete the finance-related SMS information after we have
          determined your risk profile.
        </p>
        <p></p>
        <p>* Contact List</p>
        <p></p>
        <p>
          We need you to manually select the emergency contacts from your
          contact list including name and phone number, this feature is for when
          we cannot contact you. Only contacts manually selected by users will
          be transmitted to our application(https://app-ph.we-peso.com) in an
          encrypted manner, and They will be saved for 1 year from the upload
          date. We will never collect your information without consent. If you
          want to delete your private data, you can send an email to
          dataprivacy@we-peso.com . Submit a request to customer service and we
          can manually remove it within (3-5 business days).
        </p>
        <p></p>
        <p>* Cookies</p>
        <p></p>
        <p>
          Some of your data and information will be gathered through the use of
          'cookies'. Cookies are small bits of information that are
          automatically stored on a person's web browser in their computer that
          can be retrieved by this site. Such information, for example, may be a
          user's password that is stored to avoid having to retype it during
          subsequent uses of the Application or subsequent visits to a site. We
          use cookies to give you the best possible experience on our the
          Application and website. Should you wish to disable these cookies, you
          may do so by changing the settings on the Application or on our
          website.
        </p>
        <p></p>
        <p>
          We use cookies for a variety of reasons, such as to determine
          preferences, let users navigate between pages efficiently, verify the
          user and carry out other essential security checks.
        </p>
        <p></p>
        <p>
          However, please remember that cookies are often used to enable and
          improve certain functions on our Application and website. If you
          choose to switch certain cookies off, it is likely to affect how our
          Application and our website works. For example, if your browser is set
          to disable 'session' cookies, although you will still be able to view
          our public website, you won't be able to log on to internet banking.
        </p>
        <p></p>
        <p>WHAT WE PROCESS</p>
        <p></p>
        <p>
          We process your personal information (“Personal Data”), including,
          among others, the following data:
        </p>
        <p></p>
        <p>
          Identification data, such as, among others your name, date and place
          of birth, age, government ID number, email address, mailing address,
          telephone or mobile number, title, nationality, gender, and a specimen
          signature, postal code, social security number, as well as the name,
          date of birth, gender, email address, mailing address, and telephone
          or mobile number of your relatives and spouse (“relatives”), when
          applicable;
        </p>
        <p></p>
        <p>
          Transaction data, such as your bank account number, any deposits,
          withdrawals and transfers made to or from your account, and when and
          where these took place, customer account number, card number, etc.;
        </p>
        <p></p>
        <p>
          Financial data, such as invoices, bank statements, credit notes,
          payslips, payment behaviour, financial information documents, the
          value of your property or other assets, your credit history, credit
          capacity, financial products you have with WePeso, whether you are
          registered with a credit register, payment arrears and information on
          your income, etc.;
        </p>
        <p></p>
        <p>
          SMS log information (including the sender number, sender name, text,
          sending or receiving time), to reduce the time of your request,
          analysis of the credential risks, and be able to provide personalized
          offers.We will delete SMS log after risk assessment and never share it
          with third parties.
        </p>
        <p></p>
        <p>
          Socio-demographic profile, such as education, employment details
          including the office address and contact number/s of your employer and
          co-employees, career track, customer segment, whether you are married
          and have children, etc.;
        </p>
        <p></p>
        <p>
          Behaviour and preferences data, such as data you share with us when
          you fill up our survey or when you contact our Customer Care channel,
          IP address of the mobile device or computer you use and the pages you
          visit on WePeso websites and the Application;
        </p>
        <p></p>
        <p>
          Know your customer (KYC) data as part of customer due diligence and to
          prevent fraudulent conduct or behaviour that contravenes local and
          international sanctions and to comply with regulations against money
          laundering, terrorism financing, and tax fraud;
        </p>
        <p></p>
        <p>
          Audio-visual data, where applicable and legally permissible, we
          process recordings of phone or video calls. We can use these
          recordings to verify telephone requests, for example, or for fraud
          prevention or staff training purposes;
        </p>
        <p></p>
        <p>
          Biometric data, such as facial recognition data, voice identification
          or fingerprint. We will use biometric data for customer verification;
        </p>
        <p></p>
        <p>
          Your interactions with WePeso on social media channels. We may follow
          public messages, posts, likes and responses to and about WePeso on the
          internet.
        </p>
        <p></p>
        <p>
          Sensitive data (health, ethnicity, religious or political beliefs,
          genetic or biometric data, or criminal data) may be processed if:
        </p>
        <p></p>
        <p>We have your explicit consent;</p>
        <p></p>
        <p>We are required or allowed to do so, by applicable local law;</p>
        <p></p>
        <p>
          You instruct us to make any payment or remittance to any person or
          entity including, without limitation, to a political party or
          religious institution ; or
        </p>
        <p></p>
        <p>
          You choose the biometrics option for recognition and authentication in
          order to access and transact using the Application.
        </p>
        <p></p>
        <p>
          Information from cookies, or other technologies deployed for analysis
          of visits to, usage and transactions in websites, mobile applications,
          SMS or the use of any information technology application of and/or
          used by us, social media networks, data aggregators and data
          integrators and other available data sources and the use of the
          internet.
        </p>
        <p></p>
        <p>YOUR DUTY TO PROVIDE DATA</p>
        <p></p>
        <p>
          Your Personal Data may be needed before we may perform certain
          services and provide certain products, or when we are legally required
          to collect Personal Data. We will only ask for the relevant data to
          carry out the mentioned objectives. Failure to provide the necessary
          Personal Data, or to allow the processing, profiling and sharing of
          such Information may result in us being unable to make available or
          provide, or cause delays in the availability or provision, of products
          and services, including, without limitation, inability or delay in
          accessing and using of the Application or our website.
        </p>
        <p></p>
        <p>
          With respect to the Personal Data of third parties that you provide or
          otherwise make available to us (including, without limitation,
          Personal Data of your relatives, friends, payees, beneficiaries,
          attorneys, attorneys-in-fact, guarantors, and, employees and officers
          of your employer) (each a “Related Person”), you represent and warrant
          to us that you have provided us with their correct and up-to-date
          information, and have obtained their consent to (a) collect and
          deliver to us their Personal Data, (b) process, store, and share their
          Personal Data in accordance with this Privacy Statement, with the
          understanding that this Personal Data may be used to contact them in
          relation to your Accounts, applications for products or services, or
          Cards of WePeso.
        </p>
        <p></p>
        <p>WHAT WE DO WITH YOUR DATA</p>
        <p></p>
        <p>
          For us to provide and render our products and services to you, you
          hereby:
        </p>
        <p></p>
        <p>
          waive your confidentiality rights under the relevant bank secrecy laws
          such as but not limited to Republic Act Nos. 1405, 8791 and 8484
          (otherwise known as The Secrecy of Bank Deposits Law, The General
          Banking Law of 2000 and Access Device Regulations Act); and, allow us
          to contact you, your relatives, and/or employer through the Personal
          Data you provide to us, and make use of third parties for verification
          as part of our due diligence and Know your customer (KYC) procedures;
        </p>
        <p></p>
        <p>
          agree that you and your Related Persons’ Personal Data may be
          processed, profiled and shared in and to any country/jurisdiction as
          we consider appropriate or necessary. Such Information may also be
          processed, profiled and shared in accordance with the local practices
          and laws, rules and regulations (including any regulatory requests,
          governmental acts and orders) in such country/jurisdiction.
        </p>
        <p></p>
        <p>
          agree to indemnify and keep WePeso and its affiliates, including their
          respective stockholders, directors, officers, employees, and
          representatives free and harmless from and against any and all claims,
          suits, actions or proceedings which may arise as a result of or in
          connection with the lawful release and disclosure of your Personal
          Data.
        </p>
        <p></p>
        <p>We use your Personal Data for business purposes such as:</p>
        <p></p>
        <p>
          process applications for products and services of WePeso, our
          affiliates, merchant-partners, establish, maintain or terminate
          accounts, and establish, provide or continue banking/credit facilities
          or financial services, including, without limitation, credit, debit,
          charge, prepaid or any type of card, investment products, insurance,
          loan, mortgage, auto loan, and other financial products and services;
        </p>
        <p></p>
        <p>
          undertake activities related to the provision of such products and
          services, including, without limitation, transaction authorization,
          transaction notification and confirmation, preparation and delivery of
          card, preparation and delivery of statements, customer service and
          other support services, conduct of surveys, product/service offerings
          and related materials, and administration of rewards and loyalty
          programs, which activities would involve contacting you through
          various modes of communication including, without limitation, via the
          Application, mail, telephone call, SMS, fax, electronic mail,
          internet, mobile, social media, chat, biometric, and other
          technological tools and development.
        </p>
        <p></p>
        <p>
          verify your identity or authority, or that of representatives who
          contact us or may be contacted by us and to carry out or respond to
          requests, questions or instructions from your representatives;
        </p>
        <p></p>
        <p>
          performing agreements to which you are a party so that we can carry
          out your instruction and/or analyse your eligibility for product and
          services by taking steps prior to entering into agreements.If allowed
          under local law, and you choose to use it, we may use your face,
          fingerprint or voice as recognition for authentication into mobile
          apps and certain operations;
        </p>
        <p></p>
        <p>
          service offering and/or service development or improvement so that you
          can enjoy the utmost benefit that WePeso, its affiliates, or
          merchant-partners have to offer from time to time;
        </p>
        <p></p>
        <p>
          from time to time, providing you with newsletters and other
          communications regarding WePeso, its affiliates, and
          merchant-partners, and offering or marketing to you and to individuals
          you refer to us, products or services of WePeso, its affiliates, or
          merchant-partners, conducting market, product and service research,
          and designing or refining any such products or services offered or
          marketed, which activities would include, without limitation,
          contacting you or such individuals through various modes of
          communication including, without limitation, via the Application,
          mail, telephone call, SMS, fax, electronic mail, internet, mobile,
          social media, chat, biometric, and other technological tools and
          development;
        </p>
        <p></p>
        <p>
          enforce and defend our rights, as well as the rights of our
          affiliates, and our/their employees, officers, directors, and
          stockholders, whether such rights are contractual or otherwise,
          including, without limitation, in order to collect amounts owing from
          you;
        </p>
        <p></p>
        <p>
          undertake risk assessment, behavior analysis, statistical and trend
          analysis and planning activities, including, without limitation, to
          carry out data processing, statistical, credit, risk and anti-money
          laundering and sanctions analysis, credit scoring models, credit
          worthiness checks (which includes, without limitation, conducting
          banking, credit, financial and other background checks and reviews,
          and maintaining banking, credit and financial history of individuals);
          In this regard, we may disclose from time to time your Personal Data
          and the Personal Data of third parties that you provide to us, for
          income verification with tax authorities, banks, banking associations,
          and credit bureaus;
        </p>
        <p></p>
        <p>
          Business process execution, business management and reporting
          (including, without limitation, credit and risk management, system or
          product development and planning, insurance, audit and administrative
          purposes);
        </p>
        <p></p>
        <p>
          monitoring and recording calls and electronic communications with you
          for record keeping, quality assurance, customer service, training,
          investigation, litigation and fraud prevention purposes;
        </p>
        <p></p>
        <p>
          ensuring protection of your Personal Data, your assets with WePeso,
          and the financial system; and
        </p>
        <p></p>
        <p>
          comply with legal, regulatory, governmental, tax, law enforcement and
          compliance requirements (both local and foreign), and disclosure to
          any foreign or domestic market exchange, court, tribunal, and/or
          legal, regulatory, governmental, tax and law enforcement authority
          pursuant to relevant laws, treaties, guidelines, regulations, orders,
          or requests from such authorities), including, without limitation,
          Know your customer (KYC) data obligations, and monitoring, reporting,
          and taking action under the money laundering or terrorism financing
          prevention program.
        </p>
        <p></p>
        <p>
          comply with contractual arrangements or to support initiatives,
          projects and programs by or between financial industry self-regulatory
          organizations, financial industry bodies, or other financial
          institutions, including, without limitation, assisting other financial
          institutions to conduct background or credit checks or collect debts;
        </p>
        <p></p>
        <p>
          undertaking automated processing of your and your Related Persons’
          Personal Data for any, some, or all of the purposes set out above;
        </p>
        <p></p>
        <p>any other purpose relating to any of the foregoing.</p>
        <p></p>
        <p>
          When processing is not compatible with one of above purposes, we ask
          for your explicit consent which you may withhold or withdraw at any
          time.
        </p>
        <p></p>
        <p>
          Applicable laws require us to retain Personal Data for a period of
          time. Generally, we delete your Personal Data or bundle data at a
          certain abstraction level (aggregate), render it anonymous, and
          dispose of it in five (5) years in accordance with applicable laws and
          regulations. We may retain your Personal Data for a longer period for
          an ongoing audit, investigation, litigation, or other legal
          proceeding.
        </p>
        <p></p>
        <p>
          We may use your existing or retained Personal Data, KYC information,
          and transaction history with us (including existence of active,
          dormant, terminated, or blocked accounts) when you use our products
          and services.
        </p>
        <p></p>
        <p>WHO WE SHARE YOUR DATA WITH AND WHY</p>
        <p></p>
        <p>
          WePeso may share your and your Related Persons’ Personal Data to any
          of the following, in accordance with any of the purposes set out
          above:
        </p>
        <p></p>
        <p>our affiliates and our merchant-partners;</p>
        <p></p>
        <p>
          drawee-bank or payee-bank clearinghouse or service provider thereof,
          credit reference company or bureau, dealer, registrar, paying and
          collecting agent, insurer, credit card company, acquiring company,
          card network or association, other financial institutions in order to,
          among others and without limitation:
        </p>
        <p></p>
        <p>process certain payment and withdrawal services;</p>
        <p></p>
        <p>exchange secure financial transaction messages;</p>
        <p></p>
        <p>process payments and credit transactions locally and worldwide;</p>
        <p></p>
        <p>process electronic transactions locally and worldwide;</p>
        <p></p>
        <p>
          settle domestic and cross-border security transactions and payment
          transactions; or
        </p>
        <p></p>
        <p>
          service providers and other third parties who provide services to us
          or any of our affiliates in connection with the operation or
          maintenance of our business (including their employees and officers),
          including, without limitation, service providers who are engaged in:
        </p>
        <p></p>
        <p>
          optimizing, debugging and enhancing our products and services,
          including our mobile application;
        </p>
        <p></p>
        <p>
          designing, developing and maintaining internet-based tools and
          applications;
        </p>
        <p></p>
        <p>
          accessing and utilizing applications or and infrastructure (including,
          without limitation, cloud services);
        </p>
        <p></p>
        <p>
          marketing activities or events and managing customer communications,
          including mobile attributions and the provision of analytics;
        </p>
        <p></p>
        <p>
          preparing reports and statistics, printing materials, and designing
          products;
        </p>
        <p></p>
        <p>
          legal, auditing or other special services provided by lawyers,
          notaries, trustees, company auditors or other professional advisors;
        </p>
        <p></p>
        <p>
          identifying, investigating or preventing fraud or other misconduct
          through specialized companies;
        </p>
        <p></p>
        <p>facilitating payment and transfer of funds;</p>
        <p></p>
        <p>securing credit history and verifying ability to pay; or</p>
        <p></p>
        <p>
          performing specialized services like courier service or postal mail by
          our agents, archiving of physical records, services by contractors,
          and external service providers;
        </p>
        <p></p>
        <p>
          our and our affiliates’ research partners, and we will hold such
          researchers to the same strict requirements as WePeso employees;
        </p>
        <p></p>
        <p>
          credit reference agencies, and, in the event of default, lawyers and
          debt collection agencies;
        </p>
        <p></p>
        <p>
          any person or entity to whom we are an obligation or otherwise
          required to make disclosure pursuant to legal process or under the
          requirements of any foreign or domestic law, regulation, court order
          or agreement entered into, binding on or applying to us, or agreement
          entered into by us and any foreign or domestic governmental authority
          or between or among any two or more domestic or foreign governmental
          authorities;
        </p>
        <p></p>
        <p>
          any of our actual or proposed assignee or participant or
          sub-participant or transferee of our rights in respect of any product
          or service offered to or availed by you, or any of our or our
          affiliates’ actual or proposed assignee of all or any part of our or
          our affiliates’ assets or business;
        </p>
        <p></p>
        <p>
          any person or entity giving or proposing to give a guarantee or third
          party security to guarantee or secure your obligations to, or contract
          with, us;
        </p>
        <p></p>
        <p>
          third party reward, loyalty, privileges, programs or other related
          services and/or service or product provider;
        </p>
        <p></p>
        <p>
          charitable or non-profit making organizations or other recipients of
          donations or contributions from you;
        </p>
        <p></p>
        <p>
          The subcontractors, assignees, vendors or delegates of each of the
          above-described persons or entities.
        </p>
        <p></p>
        <p>
          Whenever we share your Personal Data with third parties, we will
          ensure that we comply with requirements based on applicable local and
          foreign, if applicable, laws and regulations.
        </p>
        <p></p>
        <p>
          WePeso may also obtain your Personal Data from our affiliates and
          merchant partners. The Personal Data shall be provided in a manner and
          form as specified in a separate agreement between WePeso and its
          partners, and shall be used by WePeso only for declared valid and
          legitimate purposes. WePeso and its partners shall take reasonable
          measures to protect the Personal Data from accidental, unauthorized,
          unlawful disclosure to other parties.
        </p>
        <p></p>
        <p>YOUR RIGHTS AND HOW WE RESPECT THEM</p>
        <p></p>
        <p>
          You have privacy rights when your Personal Data is used. Based on
          applicable laws, your privacy rights may vary from jurisdiction to
          jurisdiction. If you have questions about which rights apply to you,
          or you would like to exercise any of your rights, please get in touch
          with us. You have the following rights:
        </p>
        <p></p>
        <p>Right to access your Personal Data that we use or process;</p>
        <p></p>
        <p>
          Right to require us to correct any Personal Data which is inaccurate
          or erroneous;
        </p>
        <p></p>
        <p>
          Right to object to processing. You can object to WePeso in the
          processing of your Personal Data, including processing for direct
          marketing, automated processing or profiling. However, we will still
          process and share in the instances provided under applicable law,
          rules and regulations, such as, but not limited to:
        </p>
        <p></p>
        <p>if the Personal Data is needed pursuant to a subpoena;</p>
        <p></p>
        <p>
          if the collection and processing is necessary for the performance of
          or in relation to the contract or service between you and us, such as,
          but not limited to, your continued use of the Application or our
          website, and;
        </p>
        <p></p>
        <p>
          the Personal Data is collected and processed as a result of a legal
          obligation on our part.
        </p>
        <p></p>
        <p>Right to object to receiving commercial messages from us.</p>
        <p></p>
        <p>
          When you become a WePeso customer, we may ask you whether you want to
          receive offers for WePeso products and services, as well as those of
          our designated partners and/or third party partners. You can always
          change your mind by opting out and unsubscribing to our commercial
          email;
        </p>
        <p></p>
        <p>
          We may send you newsletters, emails, telephone calls, or mobile
          notifications informing you about these products and services. If you
          don’t want to receive these offers you have the right to object to or
          to withdraw your consent;
        </p>
        <p></p>
        <p>
          When you opt out from receiving commercial emails from us, we will
          still alert you on your banking activities and any unusual activity,
          such as when transactions have been made, your credit or debit card is
          blocked or when a transaction is requested from an unusual location;
        </p>
        <p></p>
        <p>
          Right to data deletion. When regulatory agencies or government
          authorities require us to delete your Personal Data, we no longer need
          it, we unlawfully process your data, you withdraw your consent, or you
          object to us processing your data for our own legitimate interests or
          for personalised commercial messages, we will delete your Personal
          Data from our system;
        </p>
        <p></p>
        <p>
          Right to complain if you are unsatisfied with our way of handling your
          Personal Data by filing a complaint with the National Privacy
          Commission;
        </p>
        <p></p>
        <p>
          As a data subject, you shall be indemnified for any damages sustained
          due to such inaccurate, incomplete, outdated, false, unlawfully
          obtained or unauthorized use of Personal Data, taking into account any
          violation of your rights and freedom as a data subject; and
        </p>
        <p></p>
        <p>
          When exercising your rights, we may ask you for necessary
          identification details to verify your identity. We may deny your
          request and, if permitted by law, we will notify you of the reason for
          such denial. We may charge a reasonable fee for processing your
          request. If there is any delay in response time, we will notify you
          immediately and provide reasons for the delay.
        </p>
        <p></p>
        <p>HOW WE KEEP YOUR DATA SAFE</p>
        <p></p>
        <p>
          We take appropriate technical and organizational measures (policies
          and procedures, IT security, etc.) to ensure the confidentiality and
          integrity of your Personal Data and the way they are processed. We
          have an internal policy framework and it is periodically kept up to
          date with regulations and market developments.
        </p>
        <p></p>
        <p>
          In addition, WePeso employees are subject to confidentiality
          obligations and will not disclose your Personal Data unlawfully or
          unnecessarily. You may contact WePeso if you suspect that your
          Personal Data may have been compromised, through your Application or
          sending us an email at dataprivacy@we-peso.com.
        </p>
        <p></p>
        <p>HOW TO CANCEL YOUR ACCOUNT/DELETE DATA</p>
        <p></p>
        <p>
          We provide you with the right to cancel your account after paying off
          loan orders.If you want to stop using our service and delete your
          account, please enter www.we-peso.com/delete to submit a request of
          deletion, we will ask you to provide your account information and
          related proof to verify and validate your identity; after
          verification, we will delete your account and related data.
        </p>
        <p></p>
        <p>
          We may amend this Privacy Statement from time to time to remain
          compliant with any changes in laws and/or to reflect how our business
          processes Personal Data. You will be notified prior to the effect of
          any changes.
        </p>
        <p></p>
        <p>
          To learn more about WePeso’s data privacy policies and how we use your
          Personal Data, you can chat with us through your Application, or by
          sending us an email at dataprivacy@we-peso.com.
        </p>
      </div>
    ) : localCountryKey === "cl" ? (
      <div className={"contentWrapper"}>
        <DocumentTitle title="Política de Privacidad" />
        <h2>Política de Privacidad</h2>
        <p></p>
        <p>
          Privacidad es una prioridad para nosotros, y por ello, la presente
          Política de Privacidad (la "Política de Privacidad") tiene como
          propósito informarle sobre la regulación del tratamiento de la
          información y datos personales de los usuarios que acceden a nuestra
          aplicación y/o sitio web (en adelante, colectivamente, la
          "Plataforma"), cuyo titular es WePeso.
        </p>
        <p></p>
        <p>
          Esta Política de Privacidad se incorpora y somete a los Términos y
          Condiciones. LEA ATENTAMENTE ESTA POLÍTICA DE PRIVACIDAD. ESTA
          POLÍTICA DESCRIBE LA FORMA EN QUE LA EMPRESA O SUS PROVEEDORES
          ("NOSOTROS", "NUESTRO", "WePeso") RECOGEN, ALMACENAN, ASEGURAN Y
          UTILIZAN SU INFORMACIÓN PERSONAL. AL VISITAR NUESTRA PLATAFORMA O
          UTILIZAR CUALQUIERA DE NUESTROS SERVICIOS ("SERVICIO") A TRAVÉS DE LA
          PLATAFORMA, USTED ACEPTA ESTA POLÍTICA DE PRIVACIDAD. SI NO ACEPTA
          ESTA POLÍTICA DE PRIVACIDAD, NO PODRÁ UTILIZAR LOS SERVICIOS.
        </p>
        <p></p>
        <p>Su consentimiento</p>
        <p></p>
        <p>
          Al visitar nuestra aplicación móvil WePeso ("App") o nuestro sitio web
          WePeso ("Sitio Web") (colectivamente, la "Plataforma") y utilizar los
          servicios que proporcionamos, usted expresamente consiente que
          utilicemos y divulguemos su información personal de acuerdo con esta
          Política de Privacidad. Esta Política de Privacidad se incorpora y
          somete a los Términos y Condiciones de la Plataforma. En este
          contexto, los usuarios de los Servicios pueden ser
          clientes/consumidores, o cualquier otra persona que utilice los
          Servicios o acceda a nuestra Plataforma ("usuario" o "usted" o "su").
          Si no está de acuerdo con esta Política de Privacidad o con alguna de
          sus partes, le pedimos que no utilice ni acceda a nuestra Plataforma
          ni a ninguna de sus partes.
        </p>
        <p></p>
        <p>
          Al aceptar esta Política de Privacidad, usted da su consentimiento
          para que recopilemos, utilicemos, almacenemos, procesemos y
          divulguemos su información personal según lo establecido en esta
          Política de Privacidad y en los Términos y Condiciones. Esta Política
          de Privacidad puede ser modificada en cualquier momento sin previo
          aviso. Usted es responsable de revisar regularmente esta Política de
          Privacidad para estar informado de cualquier cambio.
        </p>
        <p></p>
        <p>
          WePeso se compromete a proteger su privacidad. La información recibida
          por WePeso solo se utilizará para proporcionarle el servicio y no se
          compartirá con ningún tercero o proveedor de servicios de terceros,
          excepto como se describe en esta Política de Privacidad.
        </p>
        <p></p>
        <p>NUESTRAS RESPONSABILIDADES CON LOS DATOS PERSONALES</p>
        <p></p>
        <p>
          A partir de la Política de Privacidad, WePeso, con domicilio en
          (DOMICILIO DE LA EMPRESA), es responsable de la recogida,
          almacenamiento, divulgación y uso, incluyendo el acceso, manejo,
          transmisión o eliminación de sus datos personales que recolectamos de
          nuestros usuarios (en adelante el/los "Titular/es").
        </p>
        <p></p>
        <p>Permisos que le solicitamos</p>
        <p></p>
        <p>
          Le pedimos que lea atentamente lo siguiente para comprender nuestras
          prácticas y opiniones en relación con sus datos personales y cómo los
          trataremos.
        </p>
        <p></p>
        <p>1. SMS relacionados con finanzas (registro de SMS) </p>
        <p></p>
        <p>
          WePeso cargará y transmitirá datos de SMS relacionados con finanzas
          desde su teléfono a https://app-cl.we-peso.com/api. Los SMS se
          utilizarán para gestionar los riesgos y realizar controles antifraude.
          Utilizaremos su nombre/número de teléfono y el texto del mensaje para
          poder leer el código OTP que enviamos a su teléfono y permitirle
          iniciar sesión automáticamente en la aplicación. WePeso nunca
          compartirá los datos con terceros sin su consentimiento. Solo se
          recopilarán bajo su aprobación. Los datos se transferirán y cargarán a
          través de una conexión segura (https).
        </p>
        <p></p>
        <p>2. Datos de Contactos</p>
        <p></p>
        <p>
          Necesitamos que seleccione manualmente el contactos de emergencia
          desde su lista de contactos incluido nombre y número de teléfono, esta
          función sirve para cuando no podemos poner en contacto contigo.
          Solamente los contactos seleccionados manualmente por los usuarios se
          transmitirán a nuestra aplicación de manera encriptada, y se guardarán
          por 1 año a partir de la fecha de carga. Nunca recopilaremos su
          información sin consentimiento. Si desea eliminar sus datos privados,
          puede enviar un correo electrónico a hola@we-peso.com. Envíe una
          solicitud al servicio de atención al cliente y podemos eliminarla
          manualmente dentro de (3-5 días hábiles).
        </p>
        <p></p>
        <p>3. Datos de las aplicaciones instaladas</p>
        <p></p>
        <p>
          WePeso recolectará, almacenará y monitorizará y subirá a
          https://app-cl.we-peso.com/api la información de los metadatos de cada
          aplicación instalada, incluyendo el nombre de la aplicación y del
          paquete, la hora de instalación y actualización, el nombre de la
          versión y el código de la versión de todas las aplicaciones instaladas
          en su dispositivo. Utilizamos esta información para evaluar su
          solvencia y evitar el fraude en los préstamos. WePeso nunca compartirá
          los datos con terceros sin su permiso. Sólo se recopilarán bajo su
          consentimiento. Los datos serán transferidos y cargados a través de
          una conexión segura (https).
        </p>
        <p></p>
        <p>4. Datos del dispositivo</p>
        <p></p>
        <p>
          WePeso recolectará, almacenará y subirá a
          https://app-cl.we-peso.com/api información específica sobre su
          dispositivo, incluyendo su modelo de hardware, memoria RAM,
          almacenamiento; identificadores únicos del dispositivo como IMEI,
          número de serie, SSAID; información de la SIM que incluye operador de
          red, estado de roaming, códigos MNC y MCC, información WIFI que
          incluye dirección MAC e información de la red móvil. Utilizamos estos
          datos para identificar de forma exclusiva los dispositivos y evitar el
          fraude. Esto también ayuda a enriquecer su perfil crediticio y a
          proporcionarle las mejores ofertas de préstamo de acuerdo con su
          perfil. WePeso nunca compartirá los datos con terceros sin su permiso.
          Sólo se recopilarán bajo su consentimiento. Los datos serán
          transferidos y cargados a través de una conexión segura (https).
        </p>
        <p></p>
        <p>5. Almacenamiento</p>
        <p></p>
        <p>
          WePeso requiere un permiso de almacenamiento para que su KYC y otros
          documentos relevantes puedan ser descargados y guardados de forma
          segura en su teléfono. A continuación, puede cargar fácilmente los
          documentos correctos relacionados con el KYC para rellenar más
          rápidamente los detalles de la solicitud de préstamo y el proceso de
          desembolso. Esto asegura que se le proporcione una experiencia sin
          problemas al utilizar la aplicación.
        </p>
        <p></p>
        <p>6. Cámara/Imagen</p>
        <p></p>
        <p>
          Necesitamos que proporciones imágenes de tu identificación y de ti
          mismo sosteniendo la identificación desde tu cámara. Solo subiremos
          las imágenes que elijas a nuestro servidor
          (https://app-cl.we-peso.com/api) a través de un cifrado seguro. Las
          utilizamos para evaluar si la identificación es auténtica y válida, y
          si realmente eres tú quien está presentando la evaluación financiera.
        </p>
        <p></p>
        <p>7. Ubicación</p>
        <p></p>
        <p>
          Recogemos la información de localización de tu dispositivo y la
          subimos a https://app-cl.we-peso.com/api para reducir el riesgo
          asociado a tu cuenta. Es necesario estar dentro de las fronteras de
          Chile para poder optar a nuestro servicio. WePeso nunca compartirá los
          datos con terceros sin su permiso. Sólo se recopilarán bajo su
          consentimiento. Los datos serán transferidos y cargados a través de
          una conexión segura (https).
        </p>
        <p></p>
        <p>8. DATOS PERSONALES RECOGIDOS PARA EL TRATAMIENTO.</p>
        <p></p>
        <p>
          Con lo anterior, le informamos que WePeso obtendrá sus datos
          personales, ya sea directa o indirectamente, cuando usted realice una
          solicitud y proporcione sus datos personales para obtener servicios de
          crédito de WePeso, a través de nuestra página web o aplicación nativa
          de Android ("App").
        </p>
        <p></p>
        <p>
          WePeso se compromete a tratar sus siguientes Datos Personales con los
          medios antes mencionados:
        </p>
        <p></p>
        <p>a) Datos de identificación</p>
        <p></p>
        <p>b) Datos de ocupación</p>
        <p></p>
        <p>c) Datos financieros</p>
        <p></p>
        <p>d) Datos patrimoniales</p>
        <p></p>
        <p>e) Datos de su compra</p>
        <p></p>
        <p>
          f) Información de su teléfono móvil y/o Apps. Incluyendo, sin
          limitación, información relacionada con su servicio celular,
          incluyendo: (i) operador; (ii) pagos de servicios, servicios
          adicionales y/o recargas de saldo por servicio celular o servicios
          adicionales; (iii) hábitos de consumo; (iv) marca y modelo de teléfono
          móvil; y (v) Apps.
        </p>
        <p></p>
        <p>
          Estos datos pueden ser utilizados para evaluar la información del
          crédito que usted solicita, verificar su identidad, evaluar y
          calificar su capacidad crediticia y para la gestión de riesgos.
        </p>
        <p></p>
        <p>Seguridad de los datos</p>
        <p></p>
        <p>
          Todas las transmisiones están protegidas por una codificación SSL de
          128 bits. Los datos se transfieren y cargan a través de una conexión
          segura (https) al servidor: https://app-cl.we-peso.com/api
        </p>
        <p></p>
        <p>Registro de datos</p>
        <p></p>
        <p>
          Queremos informarle de que siempre que utilice nuestro servicio, en
          caso de que se produzca un error en la aplicación, recopilamos datos e
          información (a través de productos de terceros) en su teléfono
          denominados Datos de registro. Estos Datos de Registro pueden incluir
          información como la dirección de Protocolo de Internet ("IP") de su
          dispositivo, el nombre del dispositivo, la versión del sistema
          operativo, la configuración de la app cuando utiliza mi Servicio, la
          hora y la fecha de su uso del Servicio y otras estadísticas.
        </p>
        <p></p>
        <p>Cookies</p>
        <p></p>
        <p>
          Las cookies son archivos con una pequeña cantidad de datos que se
          utilizan habitualmente como identificadores únicos anónimos. Se envían
          a su navegador desde los sitios web que visita y se almacenan en la
          memoria interna de su dispositivo.
        </p>
        <p></p>
        <p>
          Este Servicio no utiliza estas "cookies" explícitamente. Sin embargo,
          la aplicación puede utilizar código y bibliotecas de terceros que
          utilizan "cookies" para recopilar información y mejorar sus servicios.
          Usted tiene la opción de aceptar o rechazar estas "cookies" y saber
          cuándo se envía una "cookie" a su dispositivo. Si decide rechazar
          nuestras cookies, es posible que no pueda utilizar algunas partes de
          este Servicio.
        </p>
        <p></p>
        <p>Proveedores de servicios</p>
        <p></p>
        <p>
          Podemos emplear a empresas e individuos de terceros por las siguientes
          razones:
        </p>
        <p></p>
        <p>Para facilitar nuestro Servicio;</p>
        <p></p>
        <p>Para prestar el Servicio en nuestro nombre;</p>
        <p></p>
        <p>Para realizar servicios relacionados con el Servicio;</p>
        <p></p>
        <p>Para ayudarnos a analizar cómo se utiliza nuestro Servicio.</p>
        <p></p>
        <p>
          Queremos informar a los usuarios de este Servicio de que estos
          terceros tienen acceso a sus Datos Personales. El motivo es realizar
          las tareas que se les asignan en nuestro nombre. Sin embargo, están
          obligados a no divulgar ni utilizar la información para ningún otro
          fin. Enlace a la política de privacidad de los terceros proveedores de
          servicios utilizados por la app:
        </p>
        <p></p>
        <p>Google Play Services</p>
        <p></p>
        <p>Google Analytics for Firebase</p>
        <p></p>
        <p>Firebase Crashlytics</p>
        <p></p>
        <p>Facebook</p>
        <p></p>
        <p>Precauciones de seguridad</p>
        <p></p>
        <p>
          Nuestra plataforma tiene la intención de proteger su información
          personal y mantener su exactitud según lo confirmado por usted.
          Aplicamos medidas de seguridad físicas, administrativas y técnicas
          razonables para ayudarnos a proteger su información personal contra el
          acceso, el uso y la divulgación no autorizados. Por ejemplo,
          codificamos toda la información personal sensible cuando la
          transmitimos por Internet. También exigimos a nuestros proveedores de
          servicios registrados que protejan dicha información del acceso, uso y
          divulgación no autorizados. Nuestra plataforma cuenta con estrictas
          medidas de seguridad para proteger la pérdida, el uso indebido y la
          alteración de la información bajo control. Nos esforzamos por
          salvaguardar y garantizar la seguridad de la información proporcionada
          por usted. Utilizamos un sistema de encriptación basado en Secure
          Sockets Layers (SSL) para la transmisión de la información. Combinamos
          la seguridad en múltiples pasos dentro de nuestros productos con la
          tecnología más avanzada para garantizar que nuestros sistemas
          mantienen fuertes medidas de seguridad.
        </p>
        <p></p>
        <p>
          Nos esforzamos por proteger del acceso no autorizado, la alteración,
          la divulgación o la destrucción de la información que tenemos,
          incluyendo
        </p>
        <p></p>
        <p>
          a. Utilizamos la encriptación para mantener la privacidad de sus datos
          mientras están en tránsito;
        </p>
        <p></p>
        <p>
          b. Ofrecemos funciones de seguridad como la verificación OTP para
          ayudarle a proteger su cuenta;
        </p>
        <p></p>
        <p>
          c. Revisamos nuestras prácticas de recopilación, almacenamiento y
          procesamiento de información, incluidas las medidas de seguridad
          física, para evitar el acceso no autorizado a nuestros sistemas;
        </p>
        <p></p>
        <p>
          d. Restringimos el acceso a la información personal a nuestros
          empleados, contratistas y agentes que necesitan esa información para
          procesarla. Cualquier persona con este acceso está sujeta a estrictas
          obligaciones contractuales de confidencialidad y puede ser sancionada
          o despedida si no cumple con estas obligaciones;
        </p>
        <p></p>
        <p>
          e. Cumplimiento y cooperación con los reglamentos y las leyes
          aplicables;
        </p>
        <p></p>
        <p>
          f. Revisamos periódicamente esta Política de Privacidad y nos
          aseguramos de que procesamos su información de manera que se cumpla.
        </p>
        <p></p>
        <p>
          g. Cuando recibimos quejas formales por escrito, respondemos
          poniéndonos en contacto con la persona que ha presentado la queja.
          Trabajamos con las autoridades reguladoras correspondientes para
          resolver cualquier queja relativa a la transferencia de sus datos que
          no podamos resolver con usted directamente.
        </p>
        <p></p>
        <p>Conservación y eliminación de datos</p>
        <p></p>
        <p>
          WePeso se compromete a proteger sus datos personales contra el acceso
          no autorizado, el uso indebido y la divulgación utilizando las medidas
          de seguridad adecuadas según el tipo de datos y la forma en que los
          procesamos. Conservamos la información sobre usted para proporcionarle
          una experiencia sin problemas, para ponernos en contacto con usted en
          caso de que necesite ayuda y sobre su cuenta, para detectar, mitigar,
          prevenir e investigar actividades fraudulentas o ilegales. Conservamos
          sus datos durante el tiempo necesario para ofrecerle nuestros
          servicios. También podemos conservar y utilizar sus datos personales
          según sea necesario para cumplir con nuestras obligaciones legales,
          resolver conflictos y hacer cumplir nuestros acuerdos. Sin perjuicio
          de lo dispuesto en este apartado, Si desea dejar de usar nuestro
          servicio y eliminar su cuenta, ingrese a
          https://www.we-peso.com/borrar para enviar una solicitud de
          eliminación, le pediremos que proporcionar información de su cuenta y
          pruebas relacionadas para verificar y validar su identidad; Sin
          embargo, es posible que no pueda utilizar nuestros servicios después
          de la eliminación.
        </p>
        <p></p>
        <p>Sus reechoes</p>
        <p></p>
        <p>
          Utilizaremos sus datos para elaborar estadísticas relacionadas con
          nuestra base de usuarios o nuestra cartera de préstamos y podremos
          revelar dicha información a cualquier tercero para tales fines,
          siempre y cuando dicha información sea siempre anónima.
        </p>
        <p></p>
        <p>
          Si deseamos utilizar sus datos con fines de marketing, le informaremos
          antes de hacerlo. Usted tendrá derecho a impedir dicho uso
          informándonos, en un plazo de 10 días desde que se le informe del uso
          propuesto, de que no desea revelar dicha información. También puede
          ejercer el derecho en cualquier momento poniéndose en contacto con
          nosotros en hola@we-peso.com
        </p>
        <p></p>
        <p>Contacto con nosotros</p>
        <p></p>
        <p>
          Si tiene alguna pregunta o sugerencia sobre esta Política de
          Privacidad, no dude en ponerse en contacto con nosotros:
        </p>
        <p></p>
        <p>WePeso</p>
        <p></p>
        <p>Correo electrónico: hola@we-peso.com</p>
        <p></p>
        <p>Horario de oficina: De lunes a sábado de 8:30 a 17:00</p>
        <p></p>
        <p>Página web de la empresa：https://www.we-peso.com</p>
      </div>
    ) : (
      <div />
    );
  }
}
export default PrivacyAgreement;
